import React from "react";
import { Badge, Icon, Layout, Spin, Typography } from "antd";
import { Client as ConversationsClient } from "@twilio/conversations";

import "./assets/Conversation.css";
import "./assets/ConversationSection.css";
import { ReactComponent as Logo } from "./assets/twilio-mark-red.svg";

import Conversation from "./Conversation";
import LoginPage from "./LoginPage";
import { ConversationsList } from "./ConversationsList";
import { HeaderItem } from "./HeaderItem";
import Edit from "./utils/edit";

const { Content, Sider, Header } = Layout;
const { Text } = Typography;

class ConversationsApp extends React.Component {
  constructor(props) {
    super(props);

    const name = localStorage.getItem("name") || "";
    const loggedIn = name !== "";

    this.state = {
      name,
      loggedIn,
      token: null,
      statusString: null,
      conversationsReady: false,
      conversations: [],
      selectedConversationSid: null,
      newMessage: "",
      editState:false
    };
  }

  componentDidMount = () => {
    if (this.state.loggedIn) {
      this.getToken();
      this.setState({ statusString: "Fetching credentials…" });
    }
  };

  logIn = (otp) => {
    if (otp !== "") {
      localStorage.setItem("otp", otp);
      console.log('Logged In..')
      this.getToken(otp)
    }
  };

  logOut = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      name: "",
      loggedIn: false,
      token: "",
      conversationsReady: false,
      messages: [],
      newMessage: "",
      conversations: []
    });

    localStorage.removeItem("name");
    this.conversationsClient.shutdown();
  };

  getToken = async (otp) => {
    // Paste your unique Chat token function
    const myToken = await fetch('/api/otp/verify',{
      headers: {
        accept:'application/json',
        'Content-Type': 'application/json'
      },
      body:JSON.stringify({
        otp
      }),
      method:'POST'
    });

    if(myToken.status === 200 || this.state.loggedIn)
    {
      const response = await myToken.json();

      // this.setState({ name, loggedIn: true }, );

      this.setState({ token: response.token, name: response.identity,loggedIn: true}, this.initConversations);

      return;
    }

    alert('Unauthorized')

  };

  initConversations = async () => {
    window.conversationsClient = ConversationsClient;
    this.conversationsClient = new ConversationsClient(this.state.token);
    this.setState({ statusString: "Connecting to Twilio…" });

    this.conversationsClient.on("connectionStateChanged", (state) => {
      if (state === "connecting")
        this.setState({
          statusString: "Connecting to Twilio…",
          status: "default"
        });
      if (state === "connected") {
        this.setState({
          statusString: "You are connected.",
          status: "success"
        });
      }
      if (state === "disconnecting")
        this.setState({
          statusString: "Disconnecting from Twilio…",
          conversationsReady: false,
          status: "default"
        });
      if (state === "disconnected")
        this.setState({
          statusString: "Disconnected.",
          conversationsReady: false,
          status: "warning"
        });
      if (state === "denied")
        this.setState({
          statusString: "Failed to connect.",
          conversationsReady: false,
          status: "error"
        });
    });
  
    this.conversationsClient.on('conversationAdded',(conversation)=>{
      console.log('new chat',conversation)
    })

    this.conversationsClient.on('messageAdded',(payload)=>{
      console.log('new message',payload);
    })

    this.conversationsClient.on("conversationJoined", (conversation) => {
      this.setState({ conversations: [...this.state.conversations, conversation] });
    });
    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      alert('Deleted!')
      this.setState({
        conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
      });
    });

    this.conversationsClient.on('conversationUpdated',(event)=>{

      console.log('Updated',event)
      var conversations = this.state.conversations;
      const index = this.state.conversations.findIndex((conversation) => conversation.sid === event.conversation.sid)
      const conversation = this.state.conversations[index];
      if(event.updateReasons.indexOf('friendlyName') != -1){
        conversations[index] = event.conversation;
        // alert('Friendly name updated')
      }

      // if(event.updateReasons.indexOf('lastMessage') != -1){

      //   // delete conversations[index+1];
      //   conversations.unshift(event.conversation);
      // }


      // var reduced = conversations.reduce((agg,itr)=>{
      //   if(agg[itr.sid]) return agg;
      //   agg[itr.sid] = itr;
      //   return agg;
      // },{});

      // console.log(reduced);

      // conversations = Object.keys(reduced).map(key => reduced[key])

      // console.log(conversations)

      this.setState({
        conversations
      })
    })
  };

  render() {
    const { conversations, selectedConversationSid, status } = this.state;
    const selectedConversation = conversations.find(
      (it) => it.sid === selectedConversationSid
    );

    let conversationContent;
    if (selectedConversation) {
      conversationContent = (
        <Conversation
          conversationProxy={selectedConversation}
          myIdentity={this.state.name}
        />
      );
    } else if (status !== "success") {
      conversationContent = (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
                Loading your conversation!
        </div>
      )
    } else {
      conversationContent = "";
    }

    if (this.state.loggedIn) {
      return (
        <div className="conversations-window-wrapper">
          <Layout className="conversations-window-container">
            <Header
              style={{ display: "flex", alignItems: "center", padding: 0 }}
              className="custom"
            >
              <div
                style={{
                  // maxWidth: "250px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <HeaderItem style={{ paddingRight: "0", display: "flex" }}>
                  {/* <Logo /> */}
                  {/* &equiv; Mod(N) */}
                  <img src='logo-180x180.png' width={32}/>
                </HeaderItem>
                {/* <HeaderItem>
                  <Text strong style={{ color: "black" }}>
                    
                  </Text>
                </HeaderItem> */}
              </div>
              <div style={{ display: "flex", width: "100%",alignItems:'center' }}>
                <HeaderItem>
                  { this.state.editState ? ( <Edit value={selectedConversation.friendlyName || selectedConversation.sid} handleSubmit={()=> {this.setState({editState: false})}} selectedConversation={selectedConversation} /> ) : (

<Text strong style={{ color: "black" }}>
{selectedConversation &&
  (selectedConversation.friendlyName || selectedConversation.sid)}
</Text>

                  ) }
                  
                  
                  
                </HeaderItem>
                <HeaderItem style={{ float: "right", marginLeft: "auto" }}>
                  {/* <span
                    style={{ color: "black" }}
                  >{` ${this.state.statusString}`}</span> */}
                  {selectedConversation ? (
                    <Icon type="edit" style={{cursor:'pointer',marginRight:'1em'}} onClick={() => this.setState({editState: !this.state.editState})}/>
                  ) : ('') }

                  <Badge
                    dot={true}
                    status={this.state.status}
                    style={{ marginLeft: "1em" }}
                  />
                </HeaderItem>
                <HeaderItem>
                  <Icon
                    type="poweroff"
                    onClick={this.logOut}
                    style={{
                      // color: "#e25555",
                      fontSize: "20px",
                      marginLeft: "auto"
                    }}
                  />
                </HeaderItem>
              </div>
            </Header>
            <Layout className="chats-window">
              <Sider theme={"light"} width={250} breakpoint="lg" collapsedWidth="0"  style={{borderRight: '1px solid #e8e8e8'}}>
                <ConversationsList
                  conversations={conversations}
                  selectedConversationSid={selectedConversationSid}
                  onConversationClick={(item) => {
                    this.setState({ selectedConversationSid: item.sid });
                  }}
                />
              </Sider>
              <Content className="conversation-section" >
                <div id="SelectedConversation">{conversationContent}</div>
              </Content>
            </Layout>
          </Layout>
        </div>
      );
    }

    return <LoginPage onSubmit={this.logIn} />;
  }
}

export default ConversationsApp;
