import { Form, Icon, Input, Button } from 'antd';
import React from 'react';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class EditForm extends React.Component {
    componentDidMount() {
        // To disable submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
        if (!err) {
            console.log('Received values of form: ', values);

            // thisConversation.updateFriendlyName('Hi?')

            this.props.selectedConversation.updateFriendlyName(values['conversation_name']);
            this.props.handleSubmit()
        }
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        console.log('props',this.props.value)
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('conversation_name') && getFieldError('conversation_name');
        // const passwordError = isFieldTouched('password') && getFieldError('password');
        return (
        <Form layout="inline" onSubmit={this.handleSubmit}>
            <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
            {getFieldDecorator('conversation_name', {
                rules: [{ required: true, message: 'Please input your username!' }],
                initialValue: this.props.value
            })(
                <Input
                // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Conversation Name"
                />,
            )}
            
            </Form.Item>
            {/* <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
            {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
            })(
                <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
                />,
            )}
            </Form.Item> */}
            <Form.Item>
            <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                Save
            </Button>
            </Form.Item>
        </Form>
        );
    }
}

export default Form.create({ name: 'edit_form' })(EditForm);