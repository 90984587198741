export default {

    templates:{
        'partnership_arabic':'HXd35d99d5042b15c797f95b7d17d4536b',
        'partnership_english':'HXfbb7816bcd5e11e456987511f20c74fd',
        'job_seeking_en':'HX2f9b7cf4e87fa7daea510e124797601a',
        'job_seeking_ar':'HX106999f1fd5ef411bac983867f149984'
    },

    media:{
        types:['image/jpg','image/jpeg','image/png','audio/ogg','application/pdf']
    }

}