import React from 'react';
import { Layout, Button, Input, Icon, Form, Row, Col, Card, Typography, message } from 'antd';
import { ReactComponent as Logo } from './assets/twilio-mark-red.svg';
import { Alert } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

export class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disableOTP: false,
            showMessage: false,
            type: null,
            message:''
        };
    }

    handleSubmit = e => {
        e.preventDefault();

        const { form, onSubmit } = this.props;

        form.validateFields((err, values) => {
            if (!err) {
                const { otp } = values;

                console.log('OTP',otp);
                onSubmit(otp);
            }
        });
    };

    async sendOTP(){

        this.setState({disableOTP: true,showMessage: false},(e)=>{
            setTimeout(()=>{
                this.setState({disableOTP: false})
            },2 * 60000)
        })

        const myToken = await fetch('/api/otp',{
        headers: {
            accept:'application/json'
        },
        method:'POST'
        }).then((e) => {
            this.setState({
                type:'info',
                message:'OTP sent. You can request another after 2m.',
                showMessage: true,
            })
        }).catch((e)=>{
            console.log(e)
            this.setState({
                type:'warn',
                message:'OTP Error. Contact support.',
                showMessage: true,
            })
            this.setState({disableOTP: false})
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        // const usernameFieldDecorator = getFieldDecorator('username', {
        //     rules: [{ required: true, message: 'Please input your username!' }],
        // });

        const otpFieldDecorator = getFieldDecorator('otp', {
            rules: [{ required: true, message: 'Please input the One Time Password!' }],
        });

        return (
            <Layout className='custom'>
                <Content style={{ height: '100vh' }}>
                    <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
                        <Col lg={{span:6}} span={20}>
                            <Card style={{background:'white',borderRadius:'0' }}>
                                <Row type="flex" justify="center" align="middle" style={{ marginBottom: '30px' }}>
                                    {/* <Logo/> */}
                                    <img src='logo-180x180.png' width={32}/>
                                </Row>
                                <Form onSubmit={this.handleSubmit} style={{textAlign:'center'}}>
                                    <Form.Item>
                                        {otpFieldDecorator(
                                            <Input
                                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                                placeholder="OTP"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button.Group size={'default'}>
                                            <Button type="primary" style={{
                                                // background: '#e25555',
                                                border: 'none'}} htmlType="submit">
                                                Sign in
                                            </Button>
                                            <Button type="primary" onClick={()=>this.sendOTP()} disabled={this.state.disableOTP}>
                                                Send The OTP
                                            </Button>
                                        </Button.Group>
                                    </Form.Item>
                                </Form>
                                {
                                    this.state.showMessage ? (
                                        <Alert message={this.state.message} type={this.state.type} showIcon banner closable/>
                                    ) : ('')
                                }
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        )
    }
}

export default Form.create({ name: 'login' })(LoginPage);
